<template>
  <div class="justify-center m-3">
    <p class="text-3xl">Login:</p>
    <input v-model="name" type="email"
      placeholder='email'
      class="shadow border rounded w-5/6 py-2 px-3 m-2 place-items-center"><br>
    <input v-model="password" type='password'
      placeholder="password" class="shadow border rounded w-5/6 py-2 px-3 m-2">
    <br>
    <p><input v-model="cookieEnabled" type='checkbox'>
    Login speichern und Cookies erlauben *)</p><br>
    {{ loggedIn }}
    <!-- MyForm
      v-model:nameTest="myName"
      v-model:emailTest="myEmail"
    / -->
    <!--<button v-on:click="sendLogin">Login</button>-->
    <div class="flex justify-center">
    <button class="btn btn-red" @click="sendLogin($event)">Login</button>
    </div>
    <div class="text-xs">
    *) Die Verwendung von Cookies erhöht den Komfort.<br>
    Im Cookie wird Ihre Login Session gespeichert.<br>
    Durch setzen des Hackens akzeptieren Sie diese Verwendung.
    </div>
  </div>
</template>

<script>
// https://github.com/js-cookie/js-cookie
import Cookies from 'js-cookie';

export default {
  components: {
  },
  data() {
    return {
      connection: null,
      socket: {},
      connected: false,
      name: '',
      cookies: null,
      cookieEnabled: false,
      loggedIn: '',
    };
  },

  mounted() {
    this.$store.state.socket.on('userData', (data) => {
      if (data.loggedIn === false) {
        this.loggedIn = 'wrongLogin';
      } else {
        this.loggedIn = 'OK';
        this.$router.push('hours');
      }

      console.log(data);
      this.$store.state.user.loggedIn = data.loggedIn;
      this.$store.state.user.username = data.username;
      this.$store.state.user.session = data.session;
      this.$store.state.user.userId = data.userId;
      this.$store.state.user.name = `${data.firstName} ${data.lastName}`;

      if (this.cookieEnabled) {
        Cookies.set('fth_cookieSessionLoggedIn', data.loggedIn);
        Cookies.set('fth_cookieSessionSession', data.session);
        Cookies.set('fth_cookieSessionUserId', data.userId);
      }
    });

    this.cookies = Cookies.get();
    console.log('Cookies:');
    console.log(this.cookies);

    // Cookies.set('fth_cookieSessionLoggedIn', data.loggedIn);
    // Cookies.set('fth_cookieSessionSession', data.session);
    // Cookies.set('fth_cookieSessionUserId', data.userId);

    if (this.cookies.fth_cookieSessionLoggedIn === 'true') {
      console.log('logged in by cookie. Session:');
      console.log(this.cookies.fth_cookieSessionSession);
      this.$store.state.user.loggedIn = this.cookies.fth_cookieSessionLoggedIn;
      this.$store.state.user.userId = this.cookies.fth_cookieSessionUserId;
      this.$store.state.user.session = this.cookies.fth_cookieSessionSession;

      console.log(this.$store.state.user);
    }

    this.cookieEnabled = Cookies.get('fth_cookieEnabled');
    this.cookies = Cookies.get();
  },

  methods: {
    sendLogin() {
      const data = {
        name: this.name,
        password: this.password,
      };

      if (this.cookieEnabled) {
        Cookies.set('fth_cookieEnabled', true);
      }

      this.$store.state.socket.emit('login', data);
      console.log(`socket emit: login ${JSON.stringify(data)}`);
    },
  },

};
</script>
