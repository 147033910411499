
import { Options, Vue } from 'vue-class-component';
import io from 'socket.io-client';
import Login from './views/Login.vue';

@Options({
  components: { Login },
  data() {
    return {
      cookieEnabled: false,
      test: false,
      popUpMenue: false,
    };
  },
  methods: {
  },
  created() {
    // const socket = io('https://www.fth-engineering.de:3003', { transports : ['websocket'] });
    console.log('this.$store.state.socket:');
    console.log(this.$store.state.socket);
    console.log('Starting Connection to WebSocket Server');
    // this.$store.state.socket = io('https://www.fth-engineering.de:3003',
    this.$store.state.socket = io('https://socket.fth-engineering.de',
      { transports: ['websocket'] });
    /*
    if (this.$store.state.socket === {}) {
    }
    */
  },
})

export default class Home extends Vue {}
