
import { Vue, Options } from 'vue-class-component';

@Options({
  data() {
    return {
      graphWidth: 0,
      graphHeight: 0,
      colors: [
        '#0074d9',
        '#d96400',
        '#00d964',
        '#d9d900',
      ],
      seriesData: [],
      viewInPix: [70, 25, 740, 470],
      hover: false,
    };
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    legendEnabled: {
      type: Boolean,
      default: 1,
    },
    xMin: {
      type: Number,
      default: null,
    },
    xMax: {
      type: Number,
      default: null,
    },
    xIntervalls: {
      type: Number,
      default: 8,
    },
    xlabel: {
      type: String,
      default: '',
    },
    xType: {
      type: String,
      default: 'NUMBER',
    },
    yMin: {
      type: Number,
      default: null,
    },
    yMax: {
      type: Number,
      default: null,
    },
    yIntervalls: {
      type: Number,
      default: 5,
    },
    ylabel: {
      type: String,
      default: '',
    },
    series: {
      type: Array,
    },
  },
  created() {
    this.$store.commit('addGraph', this.name);
    // console.log(this.name);
  },
  mounted() {
    this.matchHeight();
    this.calcMaxMin();
    // this.copyAndScaleSeriesData();
  },
  watch: {
  },
  computed: { // zugriff auf werte vom store Vuex
    series2() {
      // return this.$store.state.graphs.get(this.name);
      return this.$store.getters.getGraphSeries(this.name);
    },
    series2Size() {
      return this.series2.size;
    },
    xlim() {
      return this.$store.getters.getGraphxlim(this.name);
    },
    ylim() {
      const ylim = this.$store.getters.getGraphylim(this.name);
      if (this.yMin != null) {
        ylim[0] = this.yMin;
      }
      if (this.yMax != null) {
        ylim[1] = this.yMax;
      }
      return ylim;
    },
    graphEnabled() {
      return this.$store.getters.getGraphEnabled(this.name);
    },
  },
  methods: {
    testFunction(x: any, y: any) {
      console.log('KLICK!!!!');
      console.log({ x, y });
    },
    getWeekNumber(d: Date): any[] {
      // Copy date so don't modify original
      const d2 = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      // Set to nearest Thursday: current date + 4 - current day number
      // Make Sunday's day number 7
      d2.setUTCDate(d2.getUTCDate() + 4 - (d2.getUTCDay() || 7));
      // Get first day of year
      const yearStart = new Date(Date.UTC(d2.getUTCFullYear(), 0, 1));
      // Calculate full weeks to nearest Thursday
      const weekNo = Math.ceil((((d2.valueOf() - yearStart.valueOf()) / 86400000) + 1) / 7);
      // Return array of year and week number
      return [d.getUTCFullYear(), weekNo];
    },
    getDate(d: any): any {
      const date = new Date(d);
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    },
    getTime(d: any): any {
      const date = new Date(d);
      let dateString = `${date.getHours().toString().padStart(2, '0')}:`;
      dateString += `${date.getMinutes().toString().padStart(2, '0')}`;
      return dateString;
    },
    matchHeight() {
      /*
      const height = this.$refs.infoBox.clientHeight;
      console.log('Höhe:');
      console.log(height);
      this.graphWidth = this.$refs.infoBox.clientWidth;
      this.graphHeigth = this.$refs.infoBox.clientHeight;
      */
    },
    copyAndScaleSeriesData() {
      for (let i = 0; i < this.series.length; i += 1) {
        this.seriesData.push({});
        this.seriesData[i].values = [];
        for (let j = 0; j < this.series[i].xValues.length; j += 1) {
          let x = this.series[i].xValues[j] - this.xlim[0];
          x /= (this.xlim[1] - this.xlim[0]);
          x *= (this.viewInPix[2] - this.viewInPix[0]);
          x += this.viewInPix[0];
          let y = this.ylim[1] - this.series[i].yValues[j] - this.ylim[0];
          y /= (this.ylim[1] - this.ylim[0]);
          y *= (this.viewInPix[3] - this.viewInPix[1]);
          y += this.viewInPix[1];
          this.seriesData[i].values.push(x);
          this.seriesData[i].values.push(y);
        }
      }
      console.log(this.seriesData);
    },
    calcMaxMin() {
      /*
      // xlim:
      if (this.xMin === null) {
        this.xlim[0] = Number.MAX_VALUE;
        for (let i = 0; i < this.series.length; i += 1) {
          const minValue = Math.min(...this.series[i].xValues);
          if (this.xlim[0] > minValue) {
            this.xlim[0] = minValue;
          }
        }
      } else {
        this.xlim[0] = this.xMin;
      }
      if (this.xMax === null) {
        this.xlim[1] = Number.MIN_VALUE;
        for (let i = 0; i < this.series.length; i += 1) {
          const maxValue = Math.max(...this.series[i].xValues);
          if (this.xlim[1] < maxValue) {
            this.xlim[1] = maxValue;
          }
        }
      } else {
        this.xlim[1] = this.xMax;
      }
      console.log('xlim:');
      console.log(this.xlim);

      // ylim:
      if (this.yMin === null) {
        this.ylim[0] = Number.MAX_VALUE;
        for (let i = 0; i < this.series.length; i += 1) {
          const minValue = Math.min(...this.series[i].yValues);
          if (this.ylim[0] > minValue) {
            this.ylim[0] = minValue;
          }
        }
      } else {
        this.ylim[0] = this.yMin;
      }
      if (this.yMax === null) {
        this.ylim[1] = Number.MIN_VALUE;
        for (let i = 0; i < this.series.length; i += 1) {
          const maxValue = Math.max(...this.series[i].yValues);
          if (this.ylim[1] < maxValue) {
            this.ylim[1] = maxValue;
          }
        }
      } else {
        this.ylim[1] = this.yMax;
      }
      console.log('ylim:');
      console.log(this.ylim);
      */
    },
  },
})

export default class Graph extends Vue {}

