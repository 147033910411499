<template>
  <div class="justify-center m-3">
    <p class="text-3xl">Stunden</p>
    <button @click="fStartWork()"
      :disabled="workTimeResult[0].startTime === workTimeResult[0].stopTime"
      class="btn btn-red disabled:btn-gray">
      Arbeitsbeginn</button>
    <button @click="fStopWork()"
      :disabled="workTimeResult[0].startTime !== workTimeResult[0].stopTime"
      class="btn btn-red disabled:btn-gray">
      Arbeitsende</button><br>
    Arbeitsbeginn: {{ workTimeResult[0].startTime }}<br>
    Arbeitsende: {{ workTimeResult[0].stopTime }}<br>
    Arbeitszeit: {{ workDurationInMinutes }} Minute(n)<br><br>
    {{ currentTime.toLocaleTimeString('de-DE') }}<br>
    <!-- div v-for="value in workTimeResultWeeks"
      :key="value.randId">
      {{ value.Jahr }} KW{{ value.KW }}: {{ value.Minuten }}
    </div -->
    <div class="aspect-w-3 aspect-h-2">
      <Graph
        :name="'workResultWeeks'"
        :xlabel="'Datum, Kalenderwoche'"
        :xType="'CW'"
        :ylabel="'Arbeitszeit [in Stunden]'"
        :yMin=0
        :yMax="yMax"
        :yIntervalls=5
        :xIntervalls=5
        :series="graphSeries"/>
    </div>
    <div>
      <input type="range" min="2" max="20" id="yMax" v-model="yMax">
      <label for="yMax">y-max: {{ yMax }} </label>
    </div>
  </div>
  <!-- div v-for="(serie, key, index) in series2"
    :key="index">{{ serie }} | {{ key }} | {{ index }}
  </div -->
</template>

<script>
import Graph from '../components/graph/Graph.vue';

export default {
  components: {
    Graph,
  },
  data() {
    return {
      pageHead: 'Arbeitszeit',
      tableView: 'fth_hours',
      currentTime: null,
      intevalId1: 0,
      workTimeResult: [{ workTime: 0 }],
      workTimeResultWeeks: [],
      workDurationInMinutes: 0,
      yMax: 10,
    };
  },

  watch: {
    // workDuration: () => this.currentTime - new Date(this.workTimeResult[0].startTime),
    currentTime(val) {
      this.workDurationInMinutes = val - new Date(this.workTimeResult[0].startTime);
      this.workDurationInMinutes = Math.floor(this.workDurationInMinutes / 60000);
    },
    // (currentTime - new Date(workTimeResult[0].startTime))
  },
  computed: { // zugriff auf werte vom store Vuex
    series2() {
      // return this.$store.state.graphs.get('workResultWeeks');
      return this.$store.getters.getGraphSeries('workResultWeeks');
    },
  },
  methods: {
    fStartWork() {
      const dataJSON = {
        user_id: this.$store.state.user.userId,
        session_id: this.$store.state.user.session,
      };

      this.$store.state.socket.emit('startWork', dataJSON);
      console.log('socket emit: startWork');
      console.log(dataJSON);
    },
    fStopWork() {
      const dataJSON = {
        user_id: this.$store.state.user.userId,
        session_id: this.$store.state.user.session,
      };

      this.$store.state.socket.emit('stopWork', dataJSON);
      console.log('socket emit: stopWork');
      console.log(dataJSON);
    },
    getWeekNumber(d) {
      // Copy date so don't modify original
      const d2 = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      // Set to nearest Thursday: current date + 4 - current day number
      // Make Sunday's day number 7
      d2.setUTCDate(d2.getUTCDate() + 4 - (d2.getUTCDay() || 7));
      // Get first day of year
      const yearStart = new Date(Date.UTC(d2.getUTCFullYear(), 0, 1));
      // Calculate full weeks to nearest Thursday
      const weekNo = Math.ceil((((d2 - yearStart) / 86400000) + 1) / 7);
      // Return array of year and week number
      return [d.getUTCFullYear(), weekNo];
    },
  },
  created() {
    const dataJSON = {
      user_id: this.$store.state.user.userId,
      session_id: this.$store.state.user.session,
      forEmployee: this.$store.state.user.userId, // TODO
    };

    this.$store.state.socket.on('workStarted', (data) => {
      console.log('workStarted');
      this.$store.state.socket.emit('getWork', dataJSON);
      console.log(data);
    });

    this.$store.state.socket.on('workStopped', (data) => {
      console.log('workStopped');
      this.$store.state.socket.emit('getWork', dataJSON);
      console.log(data);
    });

    this.$store.state.socket.on('workResult', (data) => {
      console.log('workResult');
      this.workTimeResult = data;
      console.log(data);
    });

    this.$store.state.socket.on('workResultWeeks', (data) => {
      console.log('workResultWeeks');
      console.log(data);
      // this.$store.commit('addGraph', 'workResultWeeks');
      let seriesName = '';
      if (data.forEmployee === 1) {
        seriesName = 'Stefan';
      } else if (data.forEmployee === 2) {
        seriesName = 'David';
      } else {
        seriesName = 'Unbekannt';
      }
      const seriesData = { graphName: 'workResultWeeks', seriesName };
      this.$store.commit('addSeries', seriesData);
      // this.$store.state.graphs.workResultWeeks = {};
      // console.log(this.$store.state.graphs);

      const workTimeResultWeeks = data.res;

      for (let i = 0; i < workTimeResultWeeks.length; i += 1) {
        // day1: timestamp vom 01.01.YYYY in ms seit 1.1.1970
        // day2: ms bis zur entsprechenden KW (86400000 ms = 1 Tag)
        const day1 = new Date(workTimeResultWeeks[i].Jahr, 0, 1); // (YYYY-Januar-1)
        const day2 = (workTimeResultWeeks[i].KW * 7
          - (new Date(workTimeResultWeeks[i].Jahr, 0, 1).getDay()) + 1)
          * 86400000; // tage -> ms
        const day3 = day1.valueOf() + day2;

        seriesData.x = day3;
        seriesData.y = workTimeResultWeeks[i].Minuten / 60;
        this.$store.commit('addValue', seriesData);
        // console.log(`Minuten Hinzugefügt: ${this.workTimeResultWeeks[i].Minuten}`);
      }
    });

    dataJSON.forEmployee = 1; // this.$store.state.user.userId; // für sich selbst
    this.$store.state.socket.emit('getWork', dataJSON);

    dataJSON.forEmployee = 2; // für David
    this.$store.state.socket.emit('getWork', dataJSON);

    // Timer, der die Zeit jede Sekunde aktualisiert:
    // https://stackoverflow.com/questions/43335477/how-to-use-setinterval-in-vue-component
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleTimeString
    this.currentTime = new Date(); // init first time to have a valid value before rendering DOM
    clearInterval(this.intervalId1);
    this.intevalId1 = setInterval(() => {
      this.currentTime = new Date();
      // console.log(this.intevalId1);
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.intervalId1);
  },

};
</script>
