import { createStore } from 'vuex';

export default createStore({
  state: {
    user: {
      id: 1,
      userId: -1,
      username: '',
      session: '',
      name: '',
      loggedIn: false,
    },
    socket: {},
    graphs: new Map(),
  },
  getters: {
    /**
     * Test Getter mit Parameter:
     * Kann dann über
     * this.$store.getters.getTest('wert')
     * aufgerufen werden.
     */
    getTest(state) {
      return (testvar: any) => {
        const test = testvar;
        return state.user.username + test;
      };
    },
    getGraphxlim(state) {
      return (graphName: string) => {
        const graph = state.graphs.get(graphName);
        return graph.xMinMax;
      };
    },
    getGraphylim(state) {
      return (graphName: string) => {
        const graph = state.graphs.get(graphName);
        return graph.yMinMax;
      };
    },
    getGraphEnabled(state) {
      return (graphName: string) => {
        const graph = state.graphs.get(graphName);
        return graph.enabled;
      };
    },
    getGraphSeries(state) {
      return (graphName: string) => {
        const graph = state.graphs.get(graphName);
        let returnValue = null;
        if (graph !== undefined) {
          if (graph.series !== undefined) {
            returnValue = graph.series;
          }
        }
        return returnValue;
      };
    },
  },
  mutations: {
    addGraph(state, graphName) {
      // state.graphs.set(name, new Map());
      state.graphs.set(graphName, {});
      const graph = state.graphs.get(graphName);
      graph.xMinMax = [Number.MAX_VALUE, Number.MIN_VALUE];
      graph.yMinMax = [Number.MAX_VALUE, Number.MIN_VALUE];
      graph.enabled = false; // wird auf true gesetzt, wenn skalierung bestimmt ist
      graph.series = {};
      console.log(`New Graph: ${graphName}`);
    },
    resetGraph(state, graphName) {
      state.graphs.set(graphName, {});
      const graph = state.graphs.get(graphName);
      graph.xMinMax = [Number.MAX_VALUE, Number.MIN_VALUE];
      graph.yMinMax = [Number.MAX_VALUE, Number.MIN_VALUE];
      graph.enabled = false; // wird auf true gesetzt, wenn skalierung bestimmt ist
      graph.series = {};
      console.log(`New Graph: ${graphName}`);
    },
    addSeries(state, values) {
      const graph = state.graphs.get(values.graphName);
      // graph.set(values.seriesName, []);
      graph.series[values.seriesName] = {};
      graph.series[values.seriesName].values = [];
      // graph[values.seriesName].xMinMax = [Number.MAX_VALUE, Number.MIN_VALUE];
      // graph[values.seriesName].yMinMax = [Number.MAX_VALUE, Number.MIN_VALUE];
      console.log(`New Series: ${values.seriesName}`);
    },
    addValue(state, values) {
      const graph = state.graphs.get(values.graphName);
      // const series = graph.get(values.seriesName);
      // console.log(`Series ${values.seriesName} new values: ${values.x} / ${values.y}`);
      // series.push({ x: values.x, y: values.y });
      graph.series[values.seriesName].values.push({ x: values.x, y: values.y });
      /* // minMax Wert je series:
      if (graph[values.seriesName].xMinMax[0] > values.x) {
        graph[values.seriesName].xMinMax[0] = values.x;
      }
      if (graph[values.seriesName].xMinMax[1] < values.x) {
        graph[values.seriesName].xMinMax[1] = values.x;
      }
      if (graph[values.seriesName].yMinMax[0] > values.y) {
        graph[values.seriesName].yMinMax[0] = values.y;
      }
      if (graph[values.seriesName].yMinMax[1] < values.y) {
        graph[values.seriesName].yMinMax[1] = values.y;
      }
      */
      // MinMax werte akutalliseren (x-y achsen skalierung)
      if (graph.xMinMax[0] > values.x) {
        graph.xMinMax[0] = values.x;
      }
      if (graph.xMinMax[1] < values.x) {
        graph.xMinMax[1] = values.x;
      }
      if (graph.yMinMax[0] > values.y) {
        graph.yMinMax[0] = values.y;
      }
      if (graph.yMinMax[1] < values.y) {
        graph.yMinMax[1] = values.y;
      }
      // if (graph[values.seriesName].values.length > 0 || )
      graph.enabled = true;
      // console.log(graph);
    },
  },
  actions: {
  },
  modules: {
  },
});
